import React from 'react';
import Footer from '../components/Footer';
import UIServiceGridCell from '../components/UIServiceGridCell';
import { servicios } from '../utils/data';
import useLocoScroll from '../hooks/useLocoScroll';


export default function Diferencia() {
    useLocoScroll(true);
    return (
        <>
            <div
                className="flex flex-col overflow-x-hidden bg-white lg:pt-[72px] md:pt-[72px] pt-[72px]"
                id="main-container">
                <div
                    data-scroll-section
                    className="relative flex  grow bg-negro lg:min-h-[600px] md:min-h-[600px]">
                     <div className="flex flex-col-reverse grow lg:flex-row ">
                        <div className="flex flex-col lg:pl-20 md:pl-20 lg:pb-0 md:pb-0 pt-10 pb-10 justify-center justify-self-center gap-8 md:gap-5 tracking-tight lg:text-left md:text-center w-full sm:max-w-[540px] mx-auto md:max-w-[540px] lg:max-w-[665px] text-amarillo">
                            <p className="subpixel-antialiased font-tit font-bold  tracking-normal text-center leading-none lg:text-left md:justify-center lg:text-[85px] md:text-[85px] text-[50px] lg:leading-[70px] md:leading-[70px] leading-[25px] ">
                                DESARROLLAMOS
                            </p>
                            <p className="md:w-full font-thin leading-7 md:leading-7 text-[22px] md:text-[25px] tracking-normal text-center md:text-center lg:text-left md:justify-center ">
                                herramientas tecnológicas y nos
                                <br />
                                especializamos en la ciencia del <br />
                                comportamiento para mejorar los
                                <br />
                                indicadores de tus campañas.
                            </p>
                        </div>
                        <div
                            data-scroll-speed="-1"
                            data-scroll-direction="horizontal"
                            className="relative w-full sm:max-w-90 mx-auto md:max-w-90 lg:max-w-90 justify-self-center bg-center bg-no-repeat bg-cover lg:aspect-video md:aspect-video aspect-[4/3]"
                            style={{
                                backgroundImage:
                                    'url(/assets/images/diferencia-banner.jpg)',
                            }}>
                                <img
                                    className="lg:block md:block hidden absolute h-[400px] top-1/2 transform -translate-x-1/2 -translate-y-1/2"
                                    src="/assets/images/121-03-01.png"
                                    alt="home"
                                />
                            {/* <AnimatedHeader2/> */}
                        </div>
                    </div>
                </div>

                {/* INTELLIGENCE */}
                <div data-scroll-section className="p-10 pt-10 pb-10 bg-amarillo md:pt-20 md:pb-20 md:pb-0 md:pl-20 text-center">
                    <div className="flex flex-col justify-center md:gap-3 items-center">
                        <div className="text-[18px] md:text-[20px] text-negro">
                            Conoce la herramienta
                        </div>
                        <div className="subpixel-antialiased font-tit font-bold transition-all duration-700 text-[40px] md:text-[70px] lg:text-[70px] lg:leading-[50px] md:leading-[50px] leading-[40px] text-negro text-center m-auto">
                            INTELLIGENCE
                        </div>
                        <div className=" lg:w-3/5 md:w-full text-[14px] md:text-[22px] pt-2 text-negro leading-tight text-center">
                            100% desarrollada por 121 y financiada por el Banco
                            Mundial que te permite análizar el{' '}
                            <p className="inline-block font-bold">sentiment</p> de tus redes sociales para{' '}
                            <p className="inline font-bold">
                                predecir comportamientos y perfilar campañas
                            </p>
                        </div>
                    </div>
                </div>

                <div data-scroll-section className="flex flex-col-reverse  mt-0 md:flex-row md:justify-end md:mt-0">
                    <a href="https://wa.me/51991803256" target="_blank" rel="noreferrer">
                        <div className="relative md:absolute flex left-0 w-auto px-10  leading-4  py-6 md:text-[16px] font-bold text-negro transition-all duration-1000  bg-orange">
                            Consulta con nuestro especialista
                            <svg
                                className="ml-4 animate-bounce-x"
                                width="42"
                                height="24"
                                viewBox="0 0 42 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M41.5607 13.0607C42.1464 12.4749 42.1464 11.5251 41.5607 10.9393L32.0147 1.3934C31.4289 0.807611 30.4792 0.807611 29.8934 1.3934C29.3076 1.97919 29.3076 2.92893 29.8934 3.51472L38.3787 12L29.8934 20.4853C29.3076 21.0711 29.3076 22.0208 29.8934 22.6066C30.4792 23.1924 31.4289 23.1924 32.0147 22.6066L41.5607 13.0607ZM0 13.5H40.5V10.5H0V13.5Z"
                                    fill="#000000"
                                />
                            </svg>
                        </div>
                    </a>
                    <video
                        loop
                        autoPlay
                        controls
                        muted
                        src="/assets/images/SI_Inteligence_-_Anim_1_yb0wk6.mp4"
                        type="video/mp4"
                        className="md:w-full aspect-video"
                    />
                </div>

                {/* behavior */}
                <div data-scroll-section className="pt-0 my-0 md:pt-0 pb-0 ">
                    <div className="flex flex-col justify-center items-center gap-3 p-10 lg:pt-90 md:pt-90 pt-5 bg-orange md:pt-20 md:pb-20 md:pl-20 ">
                        <div className="subpixel-antialiased font-tit font-bold transition-all duration-700 tracking-none text-[40px] md:text-[70px] lg:text-[70px] lg:leading-[50px] md:leading-[50px] leading-[25px] text-negro m-auto ">
                            BEHAVIORAL DESIGN
                        </div>
                        <div className="lg:w-3/6 md:w-full pt-2 text-[14px] md:text-[24px] text-negro leading-tight">
                            Nos especializamos con líderes mundiales en la
                            ciencia del comportamiento para darle a la
                            creatividad soporte científico que active
                            comportamientos en el consumidor.
                        </div>
                    </div>

                    <div className="flex flex-col-reverse justify-end md:flex-row md:mt-0">
                        <a href="https://wa.me/51991803256" target="_blank" rel="noreferrer">
                            <div className="relative md:absolute leading-4 flex left-0 w-auto px-10 py-6 text-[16px] font-bold text-negro transition-all duration-1000  bg-indigo">
                                Consulta con nuestro especialista
                                <svg
                                    className="ml-4 animate-bounce-x"
                                    width="42"
                                    height="24"
                                    viewBox="0 0 42 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M41.5607 13.0607C42.1464 12.4749 42.1464 11.5251 41.5607 10.9393L32.0147 1.3934C31.4289 0.807611 30.4792 0.807611 29.8934 1.3934C29.3076 1.97919 29.3076 2.92893 29.8934 3.51472L38.3787 12L29.8934 20.4853C29.3076 21.0711 29.3076 22.0208 29.8934 22.6066C30.4792 23.1924 31.4289 23.1924 32.0147 22.6066L41.5607 13.0607ZM0 13.5H40.5V10.5H0V13.5Z"
                                        fill="#000000"
                                    />
                                </svg>
                            </div>
                        </a>
                        <video
                        loop
                        autoPlay
                        controls
                        muted
                        src="/assets/images/b30fb497-085b-447a-97ea-2201733ee12b.mp4"
                        type="video/mp4"
                        className="md:w-full aspect-video"
                    />
                        {/* <img
                            className="md:w-4/5 "
                            alt='behavior'
                            src="/assets/images/LaDiferencia_video.jpg">
                        </img> */}
                    </div>
                </div>

                {/* grid */}
                <div data-scroll-section className="bg-negro md:px-10">
                    <div className="subpixel-antialiased font-tit2 font-bold tracking-wide leading-[27px] lg:leading-none text-[28px] md:leading-7 md:w-2/3 pl-2 py-16 lg:text-[48px] md:text-[30px] text-amarillo uppercase m-auto text-center">
                        Tenemos una solución para acompañarte en cada momento de
                        tu proceso de marketing y comunicación.
                    </div>
                </div>
                <div data-scroll-section className="bg-white md:px-10 md:my-5 md:py-5">
                   
                    <div className="flex flex-col sm:grid sm:grid-cols-2 lg:gap-6 md:gap-4 ">
                        {servicios.map((item, index) => {
                            return (
                                <UIServiceGridCell
                                    key={index}
                                    service={item.attributes}
                                />
                            );
                        })}
                    </div>
                </div>

                <div data-scroll-section>
                    <Footer custom next={'/soy121/'} prev={'/trabajo/'} />
                </div>
            </div>
        </>
    );
}
