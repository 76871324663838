export const talent = [
    {
        attributes: {
            image: '/assets/images/talent08-lysmat.svg',
            name: 'Alejandro Trípoli',
            charge: 'Fundador y Director General',
        },
    },
    {
        attributes: {
            image: '/assets/images/talent09-iyyzff.svg',
            name: 'Gonzalo Calmet ',
            charge: 'Fundador y Director Creativo Ejecutivo',
        },
    },
    {
        attributes: {
            image: '/assets/images/talent07-kqfv6q.svg',
            name: 'Benjamín Edwards',
            charge: 'Fundador y Director de Negocios',
        },
    },
    {
        attributes: {
            image: '/assets/images/talent06-z4ygi0.svg',
            name: 'Clara Ferro',
            charge: 'Directora de Contenidos',
        },
    },
    {
        attributes: {
            image: '/assets/images/lucas-bargen-aso1lc.svg',
            name: 'Lucas Bargen',
            charge: 'DCG',
        },
    },
    {
        attributes: {
            image: '/assets/images/talent04-ketznr.svg',
            name: 'Vanessa Ortiz',
            charge: 'Directora de Nuevos Negocios',
        },
    },
    {
        attributes: {
            image: '/assets/images/talent10-n0flma.svg',
            name: 'Luis Ruiz',
            charge: 'Director de Producción',
        },
    },
    // {
    //     attributes: {
    //         image: 'v1670354388/121%20web/121%20Talent/Fiorella_Figueroa_rj6v45.png',
    //         name: 'Fiorella Figueroa',
    //         charge: 'Directora de RR.SS.',
    //     },
    // },
];

export const servicios = [
    {
        attributes: {
            name: 'GRANDES IDEAS',
            color: '#ffc333',
            textcolor: '#000000',
            order: 'md:order-1 order-2',
            description:
                'Ideamos campañas de comunicación para generar sorpresa y atraer la atención en múltiples medios y plataformas.',
        },
    },
    {
        attributes: {
            name: 'CONSULTORÍA ESTRATÉGICA',
            color: '#e04d3d',
            textcolor: '#000000',
            order: 'md:order-2 order-4',
            description:
                'Desarrollamos estrategias potentes para tu marca, para tu plan de comunicación y para tus redes scoiales.',
        },
    },
    {
        attributes: {
            name: 'GESTIÓN DE REDES',
            color: '#3ba6ab',
            textcolor: '#000000',
            order: 'md:order-3 order-1',
            description:
                'Creamos contenidos para tus redes y las gestionamos para construir engagement y mejorar el sentimento.',
        },
    },
    {
        attributes: {
            name: 'ANALÍTICA E INSIGHT',
            color: '#000000',
            textcolor: '#ffc333',
            order: 'md:order-4 order-3',
            description:
                'Análisis y reporting, sentimento y analítica predictiva, Machine Learning aplicado a los negocios.',
        },
    },
];
