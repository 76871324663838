import Footer from '../components/Footer';
import UITalentGridCell from '../components/UITalentGridCell';
import useLocoScroll from '../hooks/useLocoScroll';
import { talent } from '../utils/data';

export default function Soy121() {
    useLocoScroll(true);

    return (
        <>
            <div
                className="flex flex-col overflow-x-hidden bg-white lg:pt-[72px] md:pt-[72px] pt-[72px]"
                id="main-container">
                <div
                    data-scroll-section
                    className="relative flex  grow lg:min-h-[600px] md:min-h-[600px] bg-negro ">
                    <div className="flex flex-col-reverse grow lg:flex-row ">
                        <div 
                            className="flex flex-col lg:pl-10 md:pl-10 lg:pb-0 md:pb-0 pt-10 pb-10 justify-center justify-self-center gap-8 md:gap-5 tracking-tight lg:text-left md:text-center w-full sm:max-w-[540px] mx-auto md:max-w-[540px] lg:max-w-[665px] text-amarillo">

                            <p className="subpixel-antialiased font-tit font-bold  tracking-wide text-center leading-none lg:text-left md:justify-center lg:text-[80px] md:text-[80px] text-[50px] lg:leading-[65px] md:leading-[65px] leading-[25px] ">
                                121 ES UN EQUIPO<br />
                                DE CREACIÓN CONTINUA
                            </p>
                            <p className="text-center md:w-full font-thin leading-6 md:leading-7 text-[20px] md:text-[25px] tracking-normal md:text-center lg:text-left md:justify-center">
                                Una idea sorprendente es el camino <br />
                                más corto entre un marca y un <br />
                                consumidor que tiene cada vez <br />
                                menos tiempo para pensar en ella.
                            </p>
                        </div>
                        <div
                            className="relative w-full sm:max-w-[540px] mx-auto md:max-w-[540px] lg:max-w-[640px] justify-self-center bg-cover bg-center bg-no-repeat bg-contain lg:aspect-video md:aspect-video aspect-[4/3]"
                            style={{
                                backgroundImage:
                                    'url(/assets/images/soy121-banner.jpg)',
                            }}>
                                 <img
                                    className="lg:block md:block hidden absolute h-[400px] top-1/2 transform -translate-x-1/2 -translate-y-1/2"
                                    src="/assets/images/121-03-01.png"
                                    alt="home"
                                />
                            {/* <AnimatedHeader2/> */}
                        </div>
                    </div>
                </div>

                <div className="pt-5 pb-16 md:px-6 bg-orange" data-scroll-section>
                    <div className="grid gap-4 md:grid-cols-3">
                        {talent.map((talent, index) => (
                            <UITalentGridCell
                                key={index}
                                talent={talent.attributes}
                            />
                        ))}
                    </div>
                </div>

                <div
                    data-scroll-section
                    className="flex flex-col items-center justify-center gap-5 px-5 py-10 md:py-10 bg-negro ">
                    <div className="subpixel-antialiased mt-10 font-tit text-center font-bold text-amarillo uppercase transition-all duration-100 lg:text-[85px] md:text-[85px] text-[40px] lg:leading-[70px] md:leading-[70px] leading-[25px] tracking-wide ">
                        EL SALTO
                    </div>
                    <div className="md:pb-3 text-[17px] md:text-[28px] text-center text-amarillo tracking-normal leading-tight">
                        Valoramos tu inconformidad, <br /> tu obsesión por
                        buscar caminos diferentes,
                        <br />y el deseo{' '}
                        <span className="font-bold">
                            por descubrir algo nuevo cada día.
                        </span>
                    </div>
                    <a
                        href="https://wa.link/wecfs7"
                        target="_blank"
                        rel="noreferrer"
                        className="subpixel-antialiased font-tit2 px-5 pt-2 pb-1 mt-0 md:mt-10 lg:mt-10 md:my-10 font-bold uppercase transition-all duration-700 bg-amarillo text-negro hover:bg-negro hover:text-amarillo lg:text-[35px] md:text-[35px] text-[25px] leading-none tracking-wide">
                        POSTULA AQUÍ
                    </a>
                </div>

                <div data-scroll-section>
                    <Footer custom next={'/hablemos/'} prev={'/diferencia/'}/>
                </div>
            </div>
        </>
    );
}
